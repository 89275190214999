@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";
body {
  font-family: 'Work Sans', sans-serif;
}

/* width */
.example::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.example::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.example::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.example::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-webkit-scrollbar {
  display: none;
}

.slick-prev:before {
  opacity: 1;
  color: #4c982a;
}
.slick-next:before {
  opacity: 1;
  color: #4c982a;
}

/* .swiper {

  z-index: none !important;
} */
/* .swiper {
  z-index: unset !important;
}
.hiji {
  z-index: unset !important;
}

.swiper-container {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: unset !important;
}

.swiper-wrapper {
  z-index: unset !important;
}

.swiper-slide {
  z-index: unset !important;
} */